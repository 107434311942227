<template>
  <div class="historyRecord">
    <div class="top-box">
      <div class="item">
        <div>{{ totalData.extract_price }}</div>
        <div class="name">实收总金额:</div>
      </div>
      <div class="item">
        <div style="color: #3d7eff">{{ totalData.actual_price }}</div>
        <div class="name">到账总金额:</div>
      </div>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <MyInput v-model="formInline.extract_name" placeholder="输入凭证号">
          <template slot="pre">凭证号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          v-model="payDate"
          start-placeholder="收费开始日期"
          end-placeholder="收费结束日期"
          @change="payDateChange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          v-model="verificationDate"
          start-placeholder="核销开始日期"
          end-placeholder="核销结束日期"
          @change="verificationDateChange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.worker_id"
          clearable
          placeholder="请选择核销人"
          @change="searchList(1)"
        >
          <el-option
            v-for="item in workerList"
            :key="item.user_id"
            :value="item.user_id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <MyButton title="搜索" class="offset" @click="searchList(1)">
          <template slot="preImage">
            <img src="../../../assets/img/icon/search.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton @click="exported" :accessId="82862">
          <span>导出</span>
          <template slot="preImage">
            <img src="../../../unit/img/down.png" alt />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      ref="tableRef"
      show-summary
      :summary-method="getSummaries"
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        label="凭证号"
        prop="extract_name"
        width="250px"
      ></el-table-column>
      <el-table-column label="收费时间" prop="extract_start" width="350px">
        <template #default="{ row }">
          <div>
            <span v-if="row.extract_name.includes('汇总核销')">
              {{ row.extract_start }}
              ~
              {{ row.extract_end }}
            </span>
            <span v-else>
              {{ row.extract_start }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="实收金额" prop="extract_price"></el-table-column>
      <!-- <el-table-column label="收费人" prop="extract_payee"></el-table-column> -->
      <el-table-column label="手续费" prop="bill_charge"></el-table-column>
      <el-table-column
        :label="formInline.bill_type == 10 ? '到账金额' : '实有现金'"
        prop="actual_price"
      ></el-table-column>
      <el-table-column label="核销人" prop="worker_name"></el-table-column>
      <el-table-column label="核销时间" prop="create_at"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="slot-box">
            <pop
              tips="异常信息"
              :accessId="63003"
              popRight
              @myclick="abnormal(scope.row)"
            >
              <img class="icon" src="../../../assets/img/icon/yicang.png" alt />
            </pop>
            <pop
              tips="取消核销"
              :accessId="85036"
              popRight
              @myclick="cancel(scope.row)"
            >
              <img class="icon" src="../../../assets/img/icon/quxiao.png" alt />
            </pop>
            <pop
              tips="详细数据"
              :accessId="61838"
              @myclick="details(scope.row)"
            >
              <img
                class="icon"
                src="../../../assets/img/icon/xiangqing.png"
                alt
              />
            </pop>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="searchList"
      ></el-pagination>
    </div>

    <el-dialog
      title="异常核对"
      :visible.sync="dialogVisible"
      width="880px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="异常原因">
          <el-input
            v-model="ruleForm.abnormal_reason"
            class="public-input"
            placeholder="请输入异常原因"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理方案">
          <el-input
            v-model="ruleForm.deal_plan"
            class="public-input"
            placeholder="请输入处理方案"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="关闭" @click="dialogVisible = false" right></MyButton>
        <MyButton title="确认" @click="confirm"></MyButton>
      </span>
    </el-dialog>

    <el-dialog
      title="详细数据"
      :visible.sync="detailsVisible"
      width="880px"
      @close="detailsClose"
      :close-on-click-modal="false"
    >
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="params.extract_name" placeholder="输入凭证号">
            <template slot="pre">凭证号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            type="daterange"
            v-model="detailsPayDate"
            start-placeholder="收费开始日期"
            end-placeholder="收费结束日期"
            @change="detailPayDateChange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" class="offset" @click="paramsList(1)">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <el-table
        :data="detailsList"
        ref="tableRef"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          label="凭证号"
          prop="voucher_number"
          width="250px"
        ></el-table-column>
        <el-table-column
          label="收费时间"
          prop="pay_time"
          width="350px"
        ></el-table-column>
        <el-table-column
          label="实收金额"
          prop="key_pay_price"
        ></el-table-column>
        <!-- <el-table-column label="收费人" prop="extract_payee"></el-table-column> -->
      </el-table>
      <div class="apiPage">
        <el-pagination
          next-text="下一页"
          prev-text="上一页"
          layout="total, prev, pager, next"
          :total="detailTotal"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          @current-change="paramsList"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <MyButton title="关闭" @click="detailsVisible = false"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "historyRecord",
  data() {
    return {
      formInline: {
        extract_status: 1,
        extract_start: "",
        extract_end: "",
        beginTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10,
        worker_id: "",
        bill_type: "",
      },
      payDate: [],
      verificationDate: [],
      tableData: [],
      total: 0,
      workerList: [],
      ruleForm: {},
      dialogVisible: false,
      detailsVisible: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        extract_id: "",
        extract_name: "",
        beginTime: "",
        endTime: "",
      },
      detailsPayDate: [],
      detailsList: [],
      detailTotal: 0,
      totalData: {
        actual_price: 0, //到账
        extract_price: 0,
      },
    };
  },
  created() {
    this.formInline.bill_type = this.$route.query.type;
    this.getList();
    this.getWorkerList();
    this.gettotal();
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (data) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " 元";
          } else {
            sums[index] = "/";
          }
        }
      });

      return sums;
    },
    confirm() {
      this.$request.HttpPost("/extract/edit", this.ruleForm).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.getList();
        this.dialogVisible = false;
      });
    },
    cancel(row) {
      this.$confirm("确认取消核销，取消之后需在账单中重新核销！", "提示", {
        type: "warning",
      }).then(() => {
        this.$request
          .HttpPost("/extract/edit", {
            extract_status: 30,
            extract_id: row.extract_id,
          })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
          });
      });
    },
    async details(row) {
      this.params.extract_id = row.extract_id;
      await this.getDetailsList();
      this.detailsVisible = true;
    },
    getDetailsList() {
      this.$request.HttpGet("/extract/listOffline", this.params).then((res) => {
        this.detailsList = res.data.list;
        this.detailTotal = res.data.total;
      });
    },
    detailPayDateChange(date) {
      if (date) {
        this.params.beginTime = date[0];
        this.params.endTime = date[1];
      } else {
        this.params.beginTime = "";
        this.params.endTime = "";
      }
    },
    paramsList(page) {
      this.params.pageNum = page;
      this.getDetailsList();
    },
    detailsClose() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        extract_id: "",
        extract_name: "",
        beginTime: "",
        endTime: "",
      };
      this.detailsPayDate = [];
      this.detailsList = [];
    },
    abnormal(row) {
      let str = row;
      this.ruleForm = { ...str };
      this.dialogVisible = true;
    },
    getWorkerList() {
      this.$request
        .HttpGet("/worker/list", {
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          this.workerList = res.data.list;
        });
    },
    searchList(page) {
      this.formInline.pageNum = page;
      this.getList();
      this.gettotal();
    },
    getList() {
      this.$request.HttpGet("/extract/list", this.formInline).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    gettotal() {
      //  this.formInline.bill_type = this.$route.query.type
      this.$request
        .HttpGet("/extract/listTotal", this.formInline)
        .then((res) => {
          if (res.data) {
            this.totalData = res.data;
          }
        });
    },
    payDateChange(date) {
      if (date) {
        this.formInline.extract_start = date[0];
        this.formInline.extract_end = date[1];
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
        this.verificationDate = [];
      } else {
        this.formInline.extract_start = "";
        this.formInline.extract_end = "";
      }
    },
    verificationDateChange(date) {
      if (date) {
        this.formInline.beginTime = date[0];
        this.formInline.endTime = date[1];
        this.formInline.extract_start = "";
        this.formInline.extract_end = "";
        this.payDate = [];
      } else {
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      }
    },
    exported() {
      this.$exported(
        "/property/extract/export",
        this.formInline,
        "线上账单核销记录.xlsx"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.historyRecord {
  margin-top: 20px;
  /deep/.el-table__footer-wrapper tbody td.el-table__cell {
    text-align: center;
  }
}
.top-box {
  height: 98px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .item {
    width: 316px;
    height: 60px;
    border-right: 1px solid #f5f5f5;
    padding-left: 98px;
    box-sizing: border-box;
    div {
      &:nth-child(1) {
        font-size: 24px;
        font-weight: bold;
      }
    }
    &:last-child {
      border-right: none;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    span {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
